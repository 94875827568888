import VariableParser from '@/helpers/VariableParser';
const filtrex = require('filtrex');
const FormulaParser = require('hot-formula-parser').Parser;
const formula_parser = new FormulaParser();
import FP from './../FormulaParser';

export default new class IfCondition {

    parse(data, values) {
        const leftText = FP.parse(data.data.leftStatement, values);
        const rightText = FP.parse(data.data.rightStatement, values);
        const trueResultText = FP.parse(data.data.trueResult, values);
        const falseResultText = FP.parse(data.data.falseResult, values);
        const left =  VariableParser.parse(leftText, values).replace(/(<([^>]+)>)/gi, '').trim();
        const right =  VariableParser.parse(rightText, values).replace(/(<([^>]+)>)/gi, '').trim();
        const expression = this.getExpression(left, data.data.operator, right);
        let evaluator = filtrex.compileExpression(expression);
        if (evaluator({})) {
            return VariableParser.parse(trueResultText, values).replace(/(<([^>]+)>)/gi, '').trim();
        }

        return VariableParser.parse(falseResultText, values).replace(/(<([^>]+)>)/gi, '').trim();
    }

    getExpression(variable, operation, value) {
        switch (operation) {
        case 'equals':
            return '"' + variable + '"' + ' == ' + '"' + value + '"';
        case 'does not equal':
            return '"' + variable + '"' + ' != ' + '"' + value + '"';
        case 'less than':
            return parseFloat(variable) + ' < ' + parseFloat(value);
        case 'more than':
            return parseFloat(variable) + ' > ' + parseFloat(value);
        case 'less than or equal to':
            return parseFloat(variable) + ' <= ' + parseFloat(value);
        case 'more than or equal to':
            return parseFloat(variable) + ' >= ' + parseFloat(value);
        case 'empty':
            return '"' + variable + '"' + ' == ' + '""';
        case 'not empty':
            return '"' + variable + '"' + ' != ' + '""';
        case 'contains':
            if (variable.includes(value)) {
                return '1 == 1';
            }
            return '1 != 1';
        case 'does not contain':
            if (!variable.includes(value)) {
                return '1 == 1';
            }
            return '1 != 1';
        case 'begins with':
            if (variable.substring(0, value.length) === value) {
                return '1 == 1';
            }
            return '1 != 1';
        case 'ends with':
            if (variable.substring(variable.length - value.length) === value) {
                return '1 == 1';
            }
            return '1 != 1';
        }
    }

};

