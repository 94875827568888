const moment = require('moment-timezone');
import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';

export default new class DateArithmeticCalculation {
    parse(data, values) {
        let first_date, second_date;
        if (data.data.first_date.type === 'Custom') {
            const text = FP.parse(data.data.first_date.date, values);
            first_date = moment.tz(VariableParser.parse(text, values).replace(/(<([^>]+)>)/gi, '').trim(), data.data.first_date.timezone);
        } else {
            if (data.data.first_date.type === 'Yesterday') {
                first_date = moment.tz(data.data.first_date.timezone).subtract(1, 'days');
            } else if (data.data.first_date.type === 'Tomorrow') {
                first_date = moment.tz(data.data.first_date.timezone).add(1, 'days');
            } else if (data.data.first_date.type === 'Days from today') {
                first_date = moment.tz(data.data.first_date.timezone).add(data.data.first_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'days');
            } else if (data.data.first_date.type === 'Days before today') {
                first_date = moment.tz(data.data.first_date.timezone).subtract(data.data.first_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'days');
            } else if (data.data.first_date.type === 'Months from today') {
                first_date = moment.tz(data.data.first_date.timezone).add(data.data.first_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'months');
            } else if (data.data.first_date.type === 'Months before today') {
                first_date = moment.tz(data.data.first_date.timezone).subtract(data.data.first_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'months');
            } else if (data.data.first_date.type === 'Years from today') {
                first_date = moment.tz(data.data.first_date.timezone).add(data.data.first_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'years');
            } else if (data.data.first_date.type === 'Years before today') {
                first_date = moment.tz(data.data.first_date.timezone).subtract(data.data.first_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'years');
            } else {
                first_date = moment.tz(data.data.first_date.timezone);
            }
        }

        if (data.data.second_date.type === 'Custom') {
            const text = FP.parse(data.data.second_date.date, values);
            second_date = moment.tz(VariableParser.parse(text, values).replace(/(<([^>]+)>)/gi, '').trim(), data.data.second_date.timezone);
        } else {
            if (data.data.second_date.type === 'Yesterday') {
                second_date = moment.tz(data.data.second_date.timezone).subtract(1, 'days');
            } else if (data.data.second_date.type === 'Tomorrow') {
                second_date = moment.tz(data.data.second_date.timezone).add(1, 'days');
            } else if (data.data.second_date.type === 'Days from today') {
                second_date = moment.tz(data.data.second_date.timezone).add(data.data.second_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'days');
            } else if (data.data.second_date.type === 'Days before today') {
                second_date = moment.tz(data.data.second_date.timezone).subtract(data.data.second_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'days');
            }  else if (data.data.second_date.type === 'Months from today') {
                second_date = moment.tz(data.data.second_date.timezone).add(data.data.second_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'months');
            } else if (data.data.second_date.type === 'Months before today') {
                second_date = moment.tz(data.data.second_date.timezone).subtract(data.data.second_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'months');
            } else if (data.data.second_date.type === 'Years from today') {
                second_date = moment.tz(data.data.second_date.timezone).add(data.data.second_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'years');
            } else if (data.data.second_date.type === 'Years before today') {
                second_date = moment.tz(data.data.second_date.timezone).subtract(data.data.second_date.units.replace(/(<([^>]+)>)/gi, '').trim(), 'years');
            } else {
                second_date = moment.tz(data.data.second_date.timezone);
            }
        }

        if (data.data.result.type === 'Date') {
            return second_date.format(data.data.result.format);
        }

        return first_date.diff(second_date, data.data.result.units.toLowerCase());
    }
};